
import {HeroImage} from "./heroImage";
import {HeroMesssge} from "./heroMessage";


export const Hero = ({hero}) => {
    
    return (
        <div className="w-full h-[580px] relative">
            <HeroImage path={ hero.mainImage} alt={hero.title} />
            <div className="backdrop-brightness-50 absolute top-0 left-0 h-full w-full">
                <div className="h-full max-w-7xl mx-auto px-5">
                    <HeroMesssge hero={hero} />
                </div>
            </div>
        </div>
    )

}
