const Title = ({bigText,smallText}) => {
  return (
    <>
      <h2 className="text-gray-800 text-lg md:text-xl lg:text-3xl font-bold">
        {bigText}
      </h2>
      <p className="text-gray-800 text-xs md:text-sm font-bold">{smallText}</p>
    </>
  );
};

export default Title;
