import BlockContent from "@sanity/block-content-to-react";
import urlFor from "../../utils/urlFor";

const CardServicos = ({data}) => {
    return (
        <div className="relative flex">
            {data.mainImage && <img className="absolute h-full w-full top-0 left-0 object-cover object-center" src={urlFor(data.mainImage).url()} alt={data.name}/>}
            <div className="backdrop-brightness-50 w-full text-gray-300 px-5 py-10 h-full prose prose-h2:text-center prose-h2:text-gray-300">

            <h2 className="">{data.title}</h2>
            <BlockContent
            dataset="production"
            projectId="hq5r21s4"
            blocks={data.body}
          />
              </div>
        </div>
    );
}

export default CardServicos;