import { ThemedButton } from "./ThemedButton";

export const CallToAction = ({message,ButtonMessage,type,path}) => {
    return (
        <div className=" flex flex-col space-y-3 justify-center items-center sm:flex-row sm:space-x-3 sm:space-y-0">
            <h2 className="text-lg font-bold tracking-wide text-gray-800">{message}</h2>
            <div className="space-x-5">
            <ThemedButton type={type} to={path}>{ButtonMessage}</ThemedButton>
            </div>
        </div>
    );
}
