import { Route, Routes } from "react-router-dom";
import { Footer } from "./components/Footer/Footer";
import { NavBar } from "./components/NavBar";
import { Empresa } from "./pages/Empresa";
import { Home } from "./pages/Home"
import { Product } from "./pages/Product";
import Servicos from "./pages/Servicos";
import { HelmetProvider } from "react-helmet-async";
function App() {
  return (
    <>
      <HelmetProvider>
        <NavBar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/empresa" element={<Empresa />} />
          <Route path="/produto" element={<Product />} />
          <Route path="/servicos" element={<Servicos />} />

        </Routes>
        <Footer />
      </HelmetProvider>
    </>
  );
}

export default App;
