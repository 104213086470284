import BlockContent from "@sanity/block-content-to-react";
import urlFor from "../../utils/urlFor";

const Right = ({ data }) => {
  return (
    <div className="w-full h-full rounded shadow-xl shadow-yellow-800 overflow-hidden">
      <h2 className="text-2xl text-center font-bold my-5">{data.title}</h2>
      <div className="md:flex justify-between w-full items-center p-2">
        <div className="backdrop-blur-sm rounded p-2 md:w-2/3">
          <BlockContent
            dataset="production"
            projectId="hq5r21s4"
            blocks={data.body}
          />
        </div>
        <div className="pr-2 pb-5  w-96  rounded overflow-hidden">
          <img className="rounded w-full h-72 object-cover object-center shadow-sm" src={data.mainImage && urlFor(data.mainImage).url()}  alt={data.title} />
        </div>
      </div>
    </div>
  );
};

export default Right;
