import React, { useEffect, useState } from "react"
import { Hero } from "./Hero";

export const HeroSection = ({ heros }) => {
        const [index, setIndex] = useState(0);
        const mod = (n, m) => {
            let result = n % m;
            return result >= 0 ? result : result + m;
        }
        useEffect(() => {
            setTimeout(() => {
                setIndex((index + 1) % heros?.length)
            }, 5000)
        }, [index,heros?.length])

        return <div className="w-full h-[580px] relative overflow-hidden">
            {heros?.map((item, i) => {
                const indexRight = mod(index + 1, heros?.length)
                let classname;
                if (i === indexRight) {
                    classname = 'transform translate-x-full transition-all duration-500 opacity-25'
                } else if (i === index) {
                    classname = 'z-50'
                }
                return <div key={item._id} className={`h-full w-full absolute inset-0 duration-500  ${classname}`}>
                    <Hero hero={item} />
                </div>
            })
            }
        </div>
}