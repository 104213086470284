import { NavLink } from "react-router-dom";

export const ThemedButton = ({ type, to, children }) => {
  function theme() {
    if (type === "primary") {
      return "bg-yellow-200";
    }
    if (type === "secondary") {
      return " bg-blue-100";
    }
    if (type === "border") {
      return "border-2 text-yellow-600 border-yellow-600";
    }
  }
  return (
    <NavLink
      to={to}
      className={`rounded px-2 py-2 text-sm text-gray-800 inline-block tracking-wide font-bold hover:bg-blue-400 ${theme()}`}
    >
      {children}
    </NavLink>
  );
};
