import ProductCard from "./ProductCard"

const ListingProducts = ({ products, filter }) => {
    function showProducts(filter) {
        if (filter === '') {
            return products?.map(item => (<ProductCard product={item} key={item._id}/>))
        } else {
            return products?.map(item => {
                return item.categories?.map(category => 
                    (category.title === filter)&&
                         (<ProductCard product={item} key={item._id}/>)
                )
            }
            )
        }
    }
    return (
        <div className="grid gap-10 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 max-w-5xl">
            {showProducts(filter)}
        </div>
    );
}

export default ListingProducts;