import { useState, useEffect } from "react";
import ProductHero from "../components/ProductHero";
import Banner from "../components/ProductHero/Banner";
import SanityClient from "../utils/client";
import {Container} from '../components/atoms/Container'
import ProductGalery from "../components/ListingProduct";
import { Helmet } from "react-helmet-async";

export const Product = () => {
  const [banner, setBanner] = useState(null);
  const [products, setProducts] = useState(null);
  
  useEffect(() => {
    SanityClient.fetch(
      `*[_type=="product"]{
                productName,
                images,
                slug,
                price,
                smallText,
                midText,
                largeText,
                details,
                categories[]->
            }["Banner" in categories[].title]`
    ).then((data) => setBanner(data));
    SanityClient.fetch(`*[_type=="product"]{
        _id,
        productName,
        images,
        slug,
        price,
        smallText,
        midText,
        largeText,
        details,
        categories[]->{
            title
        }
    }`).then((data)=>setProducts(data))
  }, []);
  return (
    <main>
      <Helmet>
        <title>
          Hellium Energia - Fornecimento e instalação de sistemas solares, com
          os melhores equipamentos.
        </title>
        <meta
          name="description"
          content="Fornecedor de equipamentos de sistemas solares. Painel solar, inversores."
        />
      </Helmet>
      {banner?.length === 1 ? (
        <div className="w-full h-[580px] relative overflow-hidden">
          <Banner product={banner[0]} />
        </div>
      ) : (
        <ProductHero productheros={banner} />
      )}
      <Container className={'py-10'}>
                <ProductGalery products={products}/>
    </Container>
    </main>
  );
};
