import { NavLink } from "react-router-dom";

export const Link = ({ to, children }) => {
    const classes = "text-center inline-flex px-1 border-b-2 border-transparent text-sm font-semibold text-gray-700 leading-5 hover:text-gray-800 hover:border-gray-300 hover:pt-1 focus:outline-none focus:text-gray-700 focus:border-gray-300 transition"
    const linkActive = "text-center inline-flex px-1 border-b-2 text-sm font-semibold text-gray-700 leading-5 hover:text-gray-800 pt-1  border-yellow-500 leading-5 text-gray-900 focus:outline-none focus:border-gray-800"
    return (
        <div>
            <NavLink to={to} className={({ isActive }) => isActive ? linkActive : classes} >{children}</NavLink>
        </div>
    );
}

