import { Link } from "../atoms/link";

export const Links = () => {
  return (
    <div className="hidden sm:flex space-x-3">
      <Link to="/">Home</Link>
      <Link to="/empresa">Empresa</Link>
      <Link to="/produto">Produtos</Link>
      <Link to="/servicos">Servicos</Link>
    </div>
  );
};
