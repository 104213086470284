import { useEffect, useState } from "react";
import { Container } from "../components/atoms/Container";
import CardServicos from "../components/card/Servicos";
import { Hero } from "../components/Hero";
import { HeroSection } from "../components/HeroSection";
import SanityClient from "../utils/client";
import Title from '../components/Title'
import { Helmet } from "react-helmet-async";
const Servicos = () => {
  const [heros, setHeros] = useState(null);
  const [sections, setSections] = useState(null);
  useEffect(() => {
    SanityClient.fetch(
      `*[_type=="hero"]{
              _id,
              title,
              slogan,
              mainImage{
                  asset->{
                      _id,
                      url
                  }
              },
              card->{
                title,
                description,
                mainImage
              },
              categories[]->
            }["Serviços" in categories[].title]`
    )
      .then((data) => setHeros(data))
      .catch(console.error);
    SanityClient.fetch(
      `*[_type=="post"]{
                _id,
                title,
                slogan,
                body,
                mainImage{
                    asset->{
                        _id,
                        url
                    }
                },
                categories[]->
              }["Serviços" in categories[].title]`
    )
      .then((data) => setSections(data))
      .catch(console.error);
  }, []);

  return (
    <main>
      <Helmet>
        <title>
          Hellium Energia - Fornecimento e instalação de sistemas solares, com
          os melhores equipamentos.
        </title>
        <meta
          name="description"
          content="Fornecedor de equipamentos de sistemas solares. Painel solar, inversores."
        />
      </Helmet>
      {heros?.length === 1 ? (
        <Hero hero={heros[0]} />
      ) : (
        <HeroSection heros={heros} />
      )}
      <Container className="py-10">
        <Title bigText={''}  />
        <section className="grid gap-5 grid-cols-1 md:grid-cols-2 px-10">
          {sections?.map((post) => (
            <CardServicos data={post} />
          ))}
        </section>
      </Container>
    </main>
  );
};

export default Servicos;
