
import BlockContent from "@sanity/block-content-to-react";
import urlFor from "../../utils/urlFor";

export const ServicesSlides = ({data}) => {
    return (
        <div className="w-full h-full flex justify-center">
            <div className="rounded overflow-hidden shadow-xl shadow-yellow-800 relative group">
                <div className="w-[250px] h-[250px] ">
                    <img className="rounded w-full h-full object-cover" src={data.mainImage && urlFor(data.mainImage).url()} alt={data.title} />
                </div>
                <div className="absolute inset-0 flex items-center backdrop-brightness-50">
                <h2 className="text-2xl text-center font-bold  w-full text-stone-100 px-2">{data.title}</h2>
                </div>

                <div className="hidden group-hover:block absolute inset-y-0 inset-x-0 backdrop-blur-sm rounded p-2 z-50 text-stone-100">
                    <BlockContent
                        dataset="production"
                        projectId="hq5r21s4"
                        blocks={data.body}
                    />
                </div>
            </div>
        </div>
    );
}