import { useEffect, useState } from "react";
import { Hero } from "../components/Hero";
import { HeroSection } from "../components/HeroSection";
import SanityClient from "../utils/client";
import { Container } from "../components/atoms/Container";
import { CallToAction } from "../components/CallToAction";
import Title from "../components/Title";
import { ServicesSlides } from "../components/Slides/ServicesSlides";
import ProductSlides from "../components/Slides/ProductSlides";
import { Helmet } from "react-helmet-async";
export const Home = () => {
  const [heros, setHeros] = useState(null);
  const [services, setServices] = useState(null);
  const [products, setProducts] = useState(null);
  useEffect(() => {
    SanityClient.fetch(
      `*[_type=="hero"]{
            _id,
            title,
            slogan,
            mainImage{
                asset->{
                    _id,
                    url
                }
            },
            card->{
              title,
              description,
              mainImage
            },
            categories[]->
          }["Landing Page" in categories[].title]`
    )
      .then((data) => setHeros(data))
      .catch(console.error);
    SanityClient.fetch(
      `*[_type=='post']{
            _id,
            title,
            mainImage,
            body,
            categories[]->
          }["Serviços" in categories[].title][0..2]`
    )
      .then((data) => setServices(data))
      .catch(console.error);

    SanityClient.fetch(
      `*[_type=="product"]{
    _id,
    productName,
    images,
    slug,
    price,
    smallText,
    midText,
    largeText,
    details,
    categories[]->{
        title
    }
  }[0..2]`
    ).then((data)=>setProducts(data));
  }, []);
  return (
    <main>
      <Helmet>
        <title>Hellium Energia - Fornecimento e instalação de sistemas solares, com os melhores equipamentos.</title>
        <meta name="description" content="Fornecedor de equipamentos de sistemas solares. Painel solar, inversores."/>
      </Helmet>
      {heros?.length === 1 ? <Hero hero={heros[0]}/> : <HeroSection heros={heros}/>}
      <Container className="py-5 px-3 space-y-8">
        <CallToAction
          message="Somos o parceiro ideal na sua transição energética"
          ButtonMessage="Quem Somos"
          type="secondary"
          path="/empresa"
        />
        <div>
          <Title
            bigText="Soluções e Serviços"
            smallText="Quer uma audutoria? Tem um projecto?"
          />
          <div className="pt-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 items-center justify-center w-full sm:max-w-xl lg:max-w-4xl mx-auto pb-6">
            {services?.map((item) => (
              <ServicesSlides data={item} key={item._id} />
            ))}
          </div>
          <CallToAction
            message={"Energia sustentável, confiável e barata"}
            ButtonMessage={"Nossos Serviços"}
            path={"/servicovs"}
            type={"secondary"}
          />
        </div>
        <div>
        <Title
          bigText="O Sol é um fonte de energia inesgotável"
          smallText="Aproveita-o com os nossos produtos"
        />
        <div className="pt-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 items-center justify-center w-full sm:max-w-xl lg:max-w-4xl mx-auto pb-6">
          {products?.map((item) => (
            <ProductSlides data={item} key={item._id} />
          ))}
        </div>
        <CallToAction
          message={"Descubra a independência Energitica"}
          ButtonMessage={"Os Nossos Produtos"}
          type={"secondary"}
          path={"/produtos"}
        />
        </div>
      </Container>
    </main>
  );
};
