import { NavLink } from "react-router-dom";
export const DropDownLink = ({to,children}) => {
    const classes = "block h-7  border-l-2 border-transparent text-sm font-semibold text-gray-700 leading-7 hover:text-gray-800 hover:border-gray-300 hover:pl-1 focus:outline-none focus:text-gray-700 focus:border-gray-300 transition"
    const linkActive = "block h-7  border-l-2  text-sm font-semibold text-gray-700 leading-7 hover:text-gray-800 border-yellow-300 pl-1 focus:outline-none focus:text-gray-700 transition"
    return (
        <>
            <NavLink to={to} className={({ isActive }) => isActive ? linkActive : classes} >{children}</NavLink>
        </>
    );
}
