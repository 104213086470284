import { useEffect, useState } from "react";
import { Container } from "../components/atoms/Container";
import { Hero } from "../components/Hero";
import { HeroSection } from "../components/HeroSection";
import Right from "../components/Sections/Right";
import SanityClient from "../utils/client";
import { Helmet } from "react-helmet-async";
export const Empresa = () => {
  const [heros, setHeros] = useState(null);
  const [sections, setSections] = useState(null);
  useEffect(() => {
    SanityClient.fetch(
      `*[_type=="hero"]{
            _id,
            title,
            slogan,
            mainImage{
                asset->{
                    _id,
                    url
                }
            },
            card->{
              title,
              description,
              mainImage
            },
            categories[]->
          }["A Empresa" in categories[].title]`
    )
      .then((data) => setHeros(data))
      .catch(console.error);
    SanityClient.fetch(
      `*[_type=="post"]{
              _id,
              title,
              slogan,
              body,
              mainImage{
                  asset->{
                      _id,
                      url
                  }
              },
              categories[]->
            }["A Empresa" in categories[].title]`
    )
      .then((data) => setSections(data))
      .catch(console.error);
  }, []);

  return (
    <main>
      <Helmet>
        <title>
          Hellium Energia - Fornecimento e instalação de sistemas solares, com
          os melhores equipamentos.
        </title>
        <meta
          name="description"
          content="Fornecedor de equipamentos de sistemas solares. Painel solar, inversores."
        />
      </Helmet>
      {heros?.length === 1 ? (
        <Hero hero={heros[0]} />
      ) : (
        <HeroSection heros={heros} />
      )}
      <Container className="px-5 py-10 space-y-10">
        {sections?.map(
          (item) => item.title === "Quem Somos" && <Right data={item} />
        )}
        {sections?.map((item, index) => {
          if (item.title === "Quem Somos") {
            return null;
          } else {
            return <Right data={item} key={item._id} />;
          }
        })}
      </Container>
    </main>
  );
};
