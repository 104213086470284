
import {Card} from "../card";

export const HeroMesssge=({hero})=>{
    return <div className="md:flex  h-full">
    <div className=" flex justify-center items-center pt-10">
        <div className="space-y-10">
            <h1 className="text-gray-300 text-3xl sm:text-6xl lg:text-8xl font-extrabold tracking-wide">{hero.title}</h1>
            <h5 className="text-gray-300 text-lg md:text-xl font-semibold tracking-wide">{hero.slogan}</h5>
        </div>
    </div>
    <div className="pt-5 flex justify-center items-center text-gray-300">
        {hero?.card !=null ? <Card card={hero.card}/> : null}
    </div>
</div>
}
