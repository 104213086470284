import Skeleton from "../card/skeleton"
import urlFor from "../../utils/urlFor";
import { Link } from "react-router-dom";
const ProductCard = ({product}) => {
    return (
            <Skeleton>
                <Link to={product.slug.current}>
                <div className="space-y-1">
                    <div className="w-full">
                        <img className="object-cover object-center drop-shadow-2xl w-[500px] h-[250px]" src={product.images && urlFor(product.images[0]).url()} alt="im"  />
                    </div>
                    <div className="px-3">
                        <div className="flex">
                            <h3 className="text-gray-800 text-xl font-extrabold w-5/6">{product.productName}</h3>
                            {product.price && <span className="w-20 px-2 text-gray-800 font-bold text-center">{product.price}CVE</span>}
                        </div>
                    </div>
                </div>
                </Link>
            </Skeleton>
    );
}

export default ProductCard;