import { useState } from "react";
import ListingProducts from "./ListingProducts";
import Tab from "./Tab";
const ProductGalery = ({products}) => {
    const [showcategory, setCatgory] = useState('allproduct')
    function switchTo(category) {
        setCatgory(showcategory => category)
    }
  return (
    <>
      <div className="py-5 flex justify-center space-x-3">
        <Tab switchTo={switchTo} filter={"allproduct"} state={showcategory}>
          Todos os Produto
        </Tab>
        <Tab switchTo={switchTo} filter={"solarpanel"} state={showcategory}>
          Paineis Solares
        </Tab>
        <Tab switchTo={switchTo} filter={"inverter"} state={showcategory}>
          Inversores
        </Tab>
      </div>
      <div className="flex justify-center">
        {showcategory === "allproduct" ? (
          <ListingProducts products={products} filter={""} />
        ) : null}
        {showcategory === "solarpanel" ? (
          <ListingProducts products={products} filter={"Painel Solar"} />
        ) : null}
        {showcategory === "inverter" ? (
          <ListingProducts products={products} filter={"Inversor"} />
        ) : null}
      </div>
    </>
  );
};

export default ProductGalery;
