import { DropDownLink } from "../atoms/DropDownLink";

export const DropDownLinks = () => {
  return (
    <div className="sm:hidden pt-3 px-2">
      <DropDownLink to="/">Home</DropDownLink>
      <DropDownLink to="/empresa">Empresa</DropDownLink>
      <DropDownLink to="/produto">Produtos</DropDownLink>
      <DropDownLink to="/servicos">Servicos</DropDownLink>
    </div>
  );
};