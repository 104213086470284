
import {AppLogo} from "../atoms/AppLogo";
import facebook from "../../assets/socialmedia/facebook.webp"
import instagram from "../../assets/socialmedia/instagram.png"
import twitter from "../../assets/socialmedia/twitter.webp"
import linkedin from "../../assets/socialmedia/linkedin.png"
import youtube from "../../assets/socialmedia/YouTube-icon.png"
export function Footer() {
  return (
    
    <footer className="border-t-2 border-slate-100 ">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col md:flex-row items-center py-2 space-y-5">
        <div className="object-contain2 h-64">
          <AppLogo />
        </div>
        {/* Contactos */}
        <div className="pl-10">
          <div className="col-span-2 justify-center items-center space-y-3">
            <div className="flex justify-between space-x-3">
              <a href="https://www.facebook.com/profile.php?id=100086440327190" target="_blank" rel="noreferrer">
                <img
                  width={100}
                  height={100}
                  className="w-10"
                  src={facebook} alt={"link to facebook"}                />
              </a>
              <a target="_blank" rel="noreferrer" href="https://twitter.com/HelliumEnergia">
                <img
                  width={100}
                  height={100}
                  className="w-10"
                  src={twitter} alt={"link to twitter"}                />
              </a>
              <a target="_blank" rel="noreferrer" href="https://www.instagram.com/cvhellium_energia/">
                <img
                  width={100}
                  height={100}
                  className="w-10"
                src={instagram} alt={"link para intagram"}                />
              </a>
              <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/87163194/admin/">
                <img
                  width={100}
                  height={100}
                  className="w-10"
                  src={linkedin} alt={"link pat linkedin"}                />
              </a>
              <a target="_blank" rel="noreferrer" href="https://www.youtube.com/channel/UChwe4OkGPUYUzp_4aWpnriQ">
                <img
                  width={100}
                  height={100}
                  className="w-10"
                  src={youtube} alt={"link para youtube"}                />
              </a>
            </div>
            <div className="pl-1">
              <a target="_blank" rel="noreferrer" href="mailto:hellium.energia@gmail.com">
                <div className="flex flex-row space-x-2 items-center">
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      className="w-6 fill-slate-600"
                    >
                      {/* Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. */}
                      <path d="M464 64C490.5 64 512 85.49 512 112C512 127.1 504.9 141.3 492.8 150.4L275.2 313.6C263.8 322.1 248.2 322.1 236.8 313.6L19.2 150.4C7.113 141.3 0 127.1 0 112C0 85.49 21.49 64 48 64H464zM217.6 339.2C240.4 356.3 271.6 356.3 294.4 339.2L512 176V384C512 419.3 483.3 448 448 448H64C28.65 448 0 419.3 0 384V176L217.6 339.2z" />
                    </svg>
                  </span>
                  <p>info@helliumenergia.cv</p>
                </div>
              </a>
            </div>
            {/* <div className="flex flex-row space-x-2 items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                className="w-6 fill-slate-600"
              > */}
                {/* Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. */}
                {/* <path d="M511.2 387l-23.25 100.8c-3.266 14.25-15.79 24.22-30.46 24.22C205.2 512 0 306.8 0 54.5c0-14.66 9.969-27.2 24.22-30.45l100.8-23.25C139.7-2.602 154.7 5.018 160.8 18.92l46.52 108.5c5.438 12.78 1.77 27.67-8.98 36.45L144.5 207.1c33.98 69.22 90.26 125.5 159.5 159.5l44.08-53.8c8.688-10.78 23.69-14.51 36.47-8.975l108.5 46.51C506.1 357.2 514.6 372.4 511.2 387z" />
              </svg>
              <p>241 234</p>
            </div> */}
          </div>
        </div>
      </div>
      <div className="border-t-2 border-slate-100 h-7">
        <h2 className="text-gray-500 text-center italic">
          HEllium Energia todos os direitos resevados
        </h2>
      </div>
    </footer>
    
  );
}


