
import {Scale} from "../transitions/Scale";
const Skeleton = (props) => {
    return (
        <Scale>
            <div className="bg-yellow-100 rounded-lg overflow-hidden relative shadow-xl group py-5 max-w-xs">
                <span className="bg-blue-200 w-2/3 h-4 absolute top-0 -left-2/3 rounded-br-lg  group-hover:translate-x-full  transition"></span>
                <span className="bg-blue-200 w-2/3 h-4 rounded-tl-lg absolute bottom-0 -right-2/3 group-hover:-translate-x-full  transition"></span>
                {/* Card Content */}
                {props.children}
            </div>
        </Scale>
    );
}

export default Skeleton;