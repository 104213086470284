import { useState } from "react";
import { AppLogo } from "../atoms/AppLogo";
import { Container } from "../atoms/Container";
import { Sandwich } from "./atoms/sandwish";
import { DropDownLinks } from "./molecules/DropDownLinks";
import { Links } from "./molecules/Links";

export const NavBar = () => {
  const [show, settoggle] = useState(false);
  const SwitchSandwich = () => {
    settoggle((show) => !show);
  };
  return (
    <>
      <nav className="shadow-lg h-18">
        <Container className="flex justify-between h-full">
          <div className="w-28">
            <AppLogo />
          </div>
          <div className="flex items-center">
            <Links />
            <Sandwich switchSandwich={SwitchSandwich} sandwich={show} />
          </div>
        </Container>
      </nav>
        {show && <DropDownLinks />}
    </>
  );
};
