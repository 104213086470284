
export const Sandwich = ({switchSandwich,sandwich}) => {
    return (
        <button
              onClick={switchSandwich}
              className="inline-flex sm:hidden items-center justify-center p-2 rounded-md text-gray-700 hover:text-gray-700 hover:bg-blue-300 focus:outline-none focus:bg-blue-300 focus:text-gray-700 transition"
            >
              <svg
                className="h-6 w-6"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
              >
                {/* Menu fechado */}
                {!sandwich ? (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                ):(
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                )}
              </svg>
            </button>
    );
}
