import urlFor from "../../utils/urlFor";


const ProductSlides = ({data}) => {
    
    return (
        <div className="w-full h-full flex justify-center">
            <div className=" rounded overflow-hidden shadow-xl shadow-yellow-800 group relative">
                <div className="w-[250px] h-[250px]">
                    <div className="w-full">
                        <img className="object-cover object-center drop-shadow-2xl" src={data.images && urlFor(data.images[0]).url()} alt="im" width={500} height={500} />
                    </div>
                </div>
                <h2 className="text-lg text-center font-bold absolute bottom-2 w-full">{data.productName}</h2>

                <div className="hidden group-hover:block absolute inset-y-0 inset-x-0 backdrop-blur-sm rounded p-2">
                    {data.details}
                </div>
            </div>
        </div>
    );
}

export default ProductSlides;