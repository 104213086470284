import { useEffect, useState } from "react";
import Banner from "./Banner";
import { AnimatePresence } from "framer-motion";

const ProductHero = ({ productheros }) => {
  const [index, setIndex] = useState(0);
  useEffect(() => {
    setTimeout(() => {
      setIndex((index + 1) % productheros?.length);
    }, 10000);
  }, [index, productheros?.length]);

  return (
    <div className="w-full h-[580px] relative overflow-hidden bg-gray-400">
      <div className={"h-full w-full absolute inset-0"}>
        <AnimatePresence initial={false} >
          {productheros?.map(
            (item, i) => index === i && <Banner key={item._id} product={item} />
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default ProductHero;
