
import {Scale} from '../transitions/Scale'


export const Card = (props) => {
    if (props.card === undefined) {
        return <div></div>
    }
    return (
        <Scale>
            <div className="bg-yellow-100 w-52 rounded-xl overflow-hidden relative shadow-xl group px-2 py-5">
                <span className="bg-blue-200 w-44 h-5 absolute top-0 left-0 rounded-br-xl -ml-44 group-hover:translate-x-44  transition"></span>
                <span className="bg-blue-200 w-44 h-5 rounded-tl-xl absolute bottom-0 right-0 -mr-44 group-hover:-translate-x-44  transition"></span>
                {/* Card Content */}
                {props.card.mainImage && <div className="rounded-lg overflow-hidden">
                    {/* <img className="h-full w-full" src={} alt="" /> */}
                </div>}
                <div className="text-center">
                    <h4 className="text-gray-800 text-2xl font-extrabold">{props.card.title}</h4>
                    <p className="text-slate-800">
                        {props.card.description}
                    </p>
                </div>
            </div>
        </Scale>
    );
}