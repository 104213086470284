import urlFor from "../../utils/urlFor";
import { motion } from "framer-motion";
const Banner = ({ product }) => {
  return (
      <motion.div className="w-screen h-full"
        key={product._id}
        initial={{opacity:0, x:"100%"}}
        animate={{opacity:1, x:"0%"}}
        transition={{duration:1,ease:"easeInOut"}}
        exit={{opacity:0,x:"-100%"}}
      >
        <div className="max-w-7xl mx-auto h-full relative ">
          <div className="absolute left-10 top-[5%] lg:top-[10%]">
            {/* Right text */}
            <h2 className="font-bold">{product.smallText}</h2>
            <h1 className="font-extrabold text-2xl drop-shadow-2xl">
              {product.midText}
            </h1>
            <h1 className="font-extrabold text-9xl text-slate-200 uppercase tracking-wider drop-shadow-2xl">
              {product.largeText}
            </h1>
            {/* Right text */}
          </div>
          {/* description */}
          {product.details && (
            <div className="absolute bottom-5 right-10 w-80 z-50">
              <h3 className="font-bold">Descrição</h3>
              <p className="text-xs">{product.details} </p>
            </div>
          )}
          {/* description */}
          <div className="h-full w-full flex justify-center items-center">
            {/* imagem */}
            <img
              className="w-96 h-96  drop-shadow-2xl"
              width={1500}
              height={1500}
              src={product.images && urlFor(product.images[0]).url()}
              alt="dbghdg"
            />
          </div>
          {/* imagem */}
        </div>
      </motion.div>
  );
};

export default Banner;
