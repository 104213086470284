import React from "react";

export const Scale = (props) => {
    return (
        <>
            <div className="transition-all transform duration-500 hover:scale-105">
                {props.children}
            </div>
        </>
    );
}
